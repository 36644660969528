/* eslint-disable max-len,jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Layout, { Seo } from '../components/Layout';
import Cover from '../components/Elements/Cover';
import PageSection from '../components/Elements/PageSection';
import withTranslations from '../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('coordinatedVulnerabilityDisclosure');

  return (
    <Layout>
      <Seo title={t('title')} />
      <Cover>
        <PageSection center>
          <h1>{t('title')}</h1>
          <i>{t('version')}</i>
          <section className="intro">
            <p>{t('intro.p1')}</p>
            <p>{t('intro.p2')}</p>
          </section>
          <section className="abuse">
            <h2>{t('abuse.title')}</h2>
            <p>
              <Trans t={t} i18nKey="abuse.p1">
                <a
                  href="https://www.exonet.nl/en/algemene-voorwaarden"
                  target="_blank"
                  rel="noopener noreferrer"
                />
                <a
                  href="https://www.exonet.nl/en/algemene-voorwaarden"
                  target="_blank"
                  rel="noopener noreferrer"
                />
                <a
                  href="mailto:abuse@exonet.nl"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="abuse@exonet.nl"
                />
              </Trans>
            </p>
          </section>
          <section className="requirements">
            <h2>{t('requirements.title')}</h2>
            <ul className="list">
              <li>
                <Trans t={t} i18nKey="requirements.list.1">
                  <a
                    href="mailto:security@exonet.nl"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="security@exonet.nl"
                  />
                  <a
                    href="https://www.exonet.nl/pgp.txt"
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                </Trans>
              </li>
              <li>{t('requirements.list.2')}</li>
              <li>{t('requirements.list.3')}</li>
              <li>{t('requirements.list.4')}</li>
              <li>{t('requirements.list.5')}</li>
            </ul>
          </section>
          <section className="perks">
            <h2>{t('perks.title')}</h2>
            <ul className="list">
              <li>{t('perks.list.1')}</li>
              <li>{t('perks.list.2')}</li>
              <li>{t('perks.list.3')}</li>
              <li>{t('perks.list.4')}</li>
              <li>{t('perks.list.5')}</li>
              <li>{t('perks.list.6')}</li>
            </ul>
          </section>
          <section className="oos">
            <h2>{t('oos.title')}</h2>
            <p>{t('oos.p1')}</p>
          </section>
          <section className="ending">
            <h2>{t('ending.title')}</h2>
            <p>{t('ending.p1')}</p>
            <i>{t('ending.thanks')}</i>
          </section>
        </PageSection>
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
